import { isBrowser } from './browser';
import { IBodySectionAnchors, IAnchors } from './types';

export const autoScrollCheckAccordion = (e) => {
  const { top, height } = e.currentTarget.getBoundingClientRect();

  top + height <= window.innerHeight / 1.5 &&
    e.currentTarget.offsetTop !== 0 &&
    window.scrollTo({
      top: e.currentTarget.offsetTop,
    });
};

export const articleFormatDate = (date: string, withDaySuffix: boolean): string => {
  const setDaySuffix = (day: number): string => {
    const getCorrectEnd = ({ condition }) => condition === true;
    const j = day % 10;
    const k = day % 100;
    const ends = [
      { value: 'st', condition: j === 1 && k !== 11 },
      { value: 'nd', condition: j === 2 && k !== 12 },
      { value: 'rd', condition: j === 3 && k !== 13 },
      { value: 'th', condition: true },
    ];
    const end = ends.find(getCorrectEnd)?.value;

    return `${day}${end}`;
  };

  const newDate = new Date(date);
  const month = newDate.toLocaleString('en-GB', { month: 'short' });
  const day = newDate.getDate();
  const year = newDate.getFullYear();
  const dateString = `${withDaySuffix ? setDaySuffix(day) : day} ${month} ${year}`;

  return dateString;
};

export const getProductDescription = (descriptionLong = '', descriptionShort = '') => {
  if (!descriptionLong) return descriptionShort;

  return descriptionLong.length >= descriptionShort.length ? descriptionLong : descriptionShort;
};

export const paginateItems = (array: any[], limitOnPage: number, activeIndex: number): any[] => {
  return array.slice(activeIndex * limitOnPage, activeIndex * limitOnPage + limitOnPage);
};

export const removeSpaces = (str: string) => str?.replace(/\s/g, '');

export const formatAnchor = (str: string) =>
  str?.replace(/[^A-Z0-9А-Я\u0590-\u05fe\u03b1-\u03c9]/gi, '').replace(/^\d+\s*/, '');

export const getCookie = (name) => {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`)
  );

  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const removeHTMLTags = (str: string) => str?.replace(/(<([^>]+)>)/gi, '');

export const replaceNbsp = (str: string) => str.replace(/[\xc2\xa0]+/g, ' ');

export const isLinkExternal = (link: string = '', target?: string): boolean =>
  link?.includes('http') || target === '_blank';

export const isActiveSimpleNavLink = (marketName: string): boolean => {
  const AU_REGEX = /au/i;
  if (!isBrowser()) return true;

  if (AU_REGEX.test(marketName)) {
    return !/^\/children\/.+/.test(window.location.pathname);
  }

  return true;
};

export const range = (length) => Array.from({ length }, (_, i) => i);

export const oddIndexes = (length) => {
  return range(length)
    .map((index) => index + 1)
    .filter((i) => i % 2 !== 0)
    .map((index) => index - 1);
};

export const evenIndexes = (length) => {
  return range(length)
    .map((index) => index + 1)
    .filter((i) => i % 2 === 0)
    .map((index) => index - 1);
};

export const getPageIdFromUmbracoId = (umbracoId: string): number =>
  parseInt(umbracoId.substring(0, umbracoId.indexOf('_')), 10);

export const getAccordionContentByHeader = (
  accordion: UmbracoTypes.IAccordion[],
  header: string
): string => accordion.find((item) => item.header === header)?.content || '';

export const getLocaleDate = (date: string, locale: string) => {
  return new Date(date).toLocaleDateString(locale, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const getStringWithoutSymbols = (str: string): string => {
  if (typeof str !== 'string') return '';
  const htmlTagsRegex = /<[^>]+>/g;
  const newLineRegex = /\n/g;
  const containsTags = htmlTagsRegex.test(str);
  const containsNewLine = newLineRegex.test(str);

  return containsTags
    ? str.replace(htmlTagsRegex, '')
    : containsNewLine
    ? str.replace(newLineRegex, ' ')
    : str;
};

export const isEmpty = (obj: unknown): boolean => {
  if (obj === undefined || obj === null) return true;
  if (Array.isArray(obj)) return !obj.length;
  if (typeof obj === 'object') return !Object.keys(obj as object).length;

  return !obj;
};

export const getSectionAnchors = (sections: IBodySectionAnchors[]): IAnchors[] =>
  sections?.reduce((anchors, section) => {
    const { sectionAnchor, additionalAnchor } = section?.properties || {};
    if (sectionAnchor) anchors.push({ anchorName: sectionAnchor });
    if (additionalAnchor) anchors.push({ anchorName: additionalAnchor });

    return anchors;
  }, [] as IAnchors[]);

export default {
  autoScrollCheckAccordion,
  articleFormatDate,
  removeSpaces,
  getCookie,
  removeHTMLTags,
  formatAnchor,
  replaceNbsp,
  isLinkExternal,
  isActiveSimpleNavLink,
  range,
  oddIndexes,
  evenIndexes,
  getPageIdFromUmbracoId,
  getLocaleDate,
  getSectionAnchors,
};
