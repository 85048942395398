import ELEMENTS from './constants';

declare global {
  interface Window {
    opera: any;
    _disableUpdateScroll?: boolean;
  }
}

export const isBrowser = () => typeof window !== 'undefined';

export const isBrowserScrollbar = (event) => {
  if (!isBrowser()) return;
  if (
    /X?HTML/.test(event.target.tagName) &&
    event.clientX >= window.document.documentElement.offsetWidth
  ) {
    return true;
  }

  return false;
};

export const isTouchDevice = () => isBrowser() && 'ontouchstart' in window;

export const getLocationPath = () => (isBrowser() ? window.location.pathname : null);

export const getLocationQS = () => (isBrowser() ? window.location.search : null);

export const getDOMRectById = (id: string): DOMRect | undefined =>
  isBrowser() ? document.getElementById(id)?.getBoundingClientRect() : undefined;

export const setUrl = (url: string): void => {
  if (isBrowser()) {
    window.history.replaceState({}, '', url);
  }
};

export const getLocationQueryStringParam = (
  param: string,
  strategy: 'string' | 'array' = 'array'
) => {
  if (!isBrowser()) {
    return strategy === 'string' ? '' : [];
  }
  const qs = new URLSearchParams(window.location.search);
  const stringValue = decodeURIComponent(qs.get(param) || '');
  if (strategy === 'string') {
    return stringValue;
  }

  try {
    return JSON.parse(`[${stringValue}]`);
  } catch (e) {
    console.error(e);

    return [];
  }
};

export const extractSearchParam = (param: string): string => {
  if (!isBrowser()) {
    return '';
  }

  let searchParam = '';

  const allParams = new URLSearchParams(window.location.search);
  const neededParams = allParams.get(param);
  if (neededParams) {
    searchParam = `?${param}=${encodeURIComponent(neededParams)}`;
  }

  return searchParam;
};

export const generateLocationQueryString = (param: string, value: string, pathname?: string) => {
  if (!isBrowser()) {
    return '';
  }
  const qs = new URLSearchParams(window.location.search);
  qs.set(param, value);

  return `${pathname || getLocationPath()}?${qs.toString()}`;
};

export const getRemovedParamFromLocationQueryString = (param: string): string | null => {
  if (!isBrowser()) {
    return '';
  }
  const qs = new URLSearchParams(window.location.search);
  qs.delete(param);

  const result = qs.toString();
  if (!result) {
    return getLocationPath();
  }

  return `${getLocationPath()}?${qs.toString()}`;
};

export const generateUrlParamsArray = (arrData: string[]): string => {
  return encodeURIComponent(JSON.stringify(arrData));
};

export const scrollToTarget = (
  el: string | HTMLElement | HTMLDivElement,
  offset: number = 0
): void => {
  if (!isBrowser()) {
    return;
  }
  const element: HTMLElement | null = typeof el === 'string' ? document.getElementById(el) : el;
  const header: HTMLElement | null = document.getElementById(ELEMENTS.HEADER_ID);
  const headerHeight: number = header ? header.clientHeight : 0;
  if (element) {
    const bodyRect: number = document.body.getBoundingClientRect().top;
    const elementRect: number = element.getBoundingClientRect().top;

    const elementPosition: number = elementRect - bodyRect;
    const offsetPosition: number = elementPosition - headerHeight;

    window.scrollTo({
      top: offsetPosition - offset,
      behavior: 'smooth',
    });
  }
};

export const getQueryParamByName = (
  name: string,
  url: string = window.location.href
): string | null => {
  // eslint-disable-next-line
  const param = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${param}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(encodeURIComponent(results[2].replace(/\+/g, ' '))).replace(
    /%20/g,
    ' '
  );
};

export const getElementOffset = (
  selector: string,
  direction: 'offsetLeft' | 'offsetTop'
): number => {
  return document.querySelector(selector)?.[direction] || 0;
};

export const getWindowHeight = (calculatedHeight?: number): number => {
  if (!isBrowser()) return 0;

  return (
    calculatedHeight ||
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  );
};
