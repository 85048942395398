const ReactDOM = require('react-dom');
const { loadableReady } = require('@loadable/component');

const { gtmService } = require('./src/services/gtmService');

exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.hydrate(element, container, callback);
    });
  };
};

exports.shouldUpdateScroll = () => {
  if (!window._disableUpdateScroll) {
    window.scrollTo(0, 0);
  }

  return false;
};

exports.wrapPageElement = ({ props }) => {
  const { pageTemplate } = props.pageContext || {};
  gtmService.emitPageView(pageTemplate);
};

exports.onRouteUpdate = () => {
  window.hrefs = window.hrefs || [];
  if (window.hrefs[window.hrefs.length - 1] !== window.location.pathname) {
    window.hrefs.push(window.location.pathname);
  }
  window.previousPath = window.hrefs[window.hrefs.length - 2];
};
